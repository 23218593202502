import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import PageRoot from '../../components/common-components/templates/page-root-v2';
import Works from '../../components/work-v2/index';
import colorPaletteV2 from '../../styles/color-palette-v2';
import '../../styles/foundation-v2.css';
import WorkFluidImages from '../../images/gatsby-image/v2-work-page/fluid-images';
import WorkDetailFluidImages from '../../images/gatsby-image/work-detail-page/fluid-images';
import pagesV2 from '../../constants-v2/pages-v2';
import SEOHeaderV2 from '../../components/common-components/templates/page-root-v2/seo-v2';

const wpContentSideMarginSp = 2.4;

const captionWidthPc = '608px';
const captionWidthSp = `calc(100% - ${wpContentSideMarginSp * 2}px)`;

const contentFontH = {
  sp: {
    h1: {
      font: '24px',
      line: '36px',
    },
    h2: {
      font: '24px',
      line: '36px',
    },
    h3: {
      font: '21px',
      line: '31.5px',
    },
    h4: {
      font: '17px',
      line: '27px',
    },
  },
  pc: {
    h1: {
      font: '24px',
      line: '34.75px',
    },
    h2: {
      font: '24px',
      line: '34.75px',
    },
    h3: {
      font: '18px',
      line: '24.06px',
    },
    h4: {
      font: '16px',
      line: '23.17px',
    },
  },
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 60vh;
`;

const BodyWrapper = styled.div`
  z-index: 1;
  margin-bottom: 160px;
  @media (max-width: 1023px) {
    width: 100%;
    margin-bottom: 100px;
  }
`;

const CaptionRightAlign = styled(Link)`
  display: flex;
  align-items: center;
  width: 110px;
  margin: 0 0 24px auto;
  @media (max-width: 1023px) {
    margin-bottom: 18px;
  }
  @media (min-width: 1024px) {
    margin-bottom: 24px;
  }
`;

const TopAttention = styled.h2`
  font-weight: 400;
  color: ${colorPaletteV2.blue};
  @media (max-width: 1023px) {
    font-size: 16px;
    line-height: 16px;
  }
  @media (min-width: 1024px) {
    font-size: 16px;
    line-height: 16px;
    margin-left: 4px;
  }
`;

const WorkContentWrapper = styled.div`
  @media (min-width: 1024px) {
    width: ${captionWidthPc};
  }
`;

const EyeCatchPic = styled(Img)`
  border: 0;
  background-size: cover;
  @media (max-width: 1023px) {
    width: ${captionWidthSp};
    height: 320px;
    margin: 0 auto 24px;
  }
  @media (min-width: 1024px) {
    width: ${captionWidthPc};
    height: 420px;
    margin-bottom: 28px;
  }
  @media (max-width: 640px) {
    height: 210px;
  }
`;

const WorkTitle = styled.h1`
  font-size: 24px;
  line-height: 34.75px;
  font-weight: 700;
  @media (max-width: 1023px) {
    margin-bottom: 12px;
  }
  @media (min-width: 1024px) {
    margin-bottom: 14px;
  }
`;

const WorkTags = styled.div`
  @media (max-width: 1023px) {
    display: block;
    line-height: 28.8px;
  }
  @media (min-width: 1024px) {
    margin-bottom: 40px;
    margin-bottom: 40px;
  }
`;

const WorkTagsItem = styled.span`
  font-size: 16px;
  color: ${colorPaletteV2.blue};
  display: inline;
  margin-right: 5px;
  cursor: pointer;
  white-space: nowrap;
  &:hover {
    text-decoration: underline;
  }
`;

const WorksOuter = styled.div`
  margin-bottom: 100px;
`;

const WorkDescriptionWrapper = styled.div`
  font-size: 16px;
  font-size: 16px;
  line-height: 28.8px;
  line-height: 28.8px;

  & h2 {
    padding: 2px 8px;
    border-left: solid 6px ${colorPaletteV2.blue};
    margin-top: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-bottom: 20px;

    @media (max-width: 1023px) {
      font-size: ${contentFontH.sp.h2.font};
      line-height: ${contentFontH.sp.h2.line};
    }
    @media (min-width: 1024px) {
      font-size: ${contentFontH.pc.h2.font};
      line-height: ${contentFontH.pc.h2.line};
    }
  }
`;

const WorkParagraph = styled.p`
  font-size: 16px;
  font-size: 16px;
  line-height: 28.8px;
  line-height: 28.8px;
  font-weight: 400;
  display: inline-block;
  margin-bottom: 24px;
`;

const WorkBlockImagePC = styled(Img)`
  @media (min-width: 1024px) {
    width: 100%;
    height: auto;
    margin: 0 auto 20px;
  }
  @media (max-width: 1023px) {
    display: none;
  }
  //TO DO:2021/03/30:公開後タブレット用画像用意する？
`;

const WorkBlockImageSP = styled(Img)`
  @media (min-width: 1024px) {
    display: none;
  }
  @media (max-width: 1023px) {
    width: 100%;
    height: auto;
    margin: 0 auto 20px;
  }
  //TO DO:2021/03/30:公開後タブレット用画像用意する？
`;

const DxSolution = () => {
  return (
    <PageRoot>
      <SEOHeaderV2 pageTitle="実績紹介｜OMO実現を見据えて顧客との接点を生むシステム開発" />
      <Root className="wrapper-v2">
        <BodyWrapper>
          <CaptionRightAlign to={pagesV2.works.path}>
            <TopAttention>実績紹介一覧</TopAttention>
          </CaptionRightAlign>
          <WorkContentWrapper>
            <EyeCatchPic fluid={WorkFluidImages('dxsolution')} />
            <WorkTitle>
              OMO実現を見据えて <br />
              顧客との接点を生むシステム開発
            </WorkTitle>
            <WorkTags>
              <WorkTagsItem>#モバイルアプリ</WorkTagsItem>
              <WorkTagsItem>#Webサイト</WorkTagsItem>
            </WorkTags>
            <WorkDescriptionWrapper>
              <h2>プロジェクトの背景</h2>
              <WorkParagraph>
                &emsp;釣具の小売業として店舗数と売り上げを拡大し続けてきた株式会社タカミヤ様は、さらなる顧客のLTV(Life&nbsp;Time&nbsp;Value)向上を模索していました。
                <br />
                顧客との接点としてモバイルアプリとWebサイトがありますが、それまでのものは、店舗や本部から情報発信するのみで、エンドユーザーから何か情報をアップロードしたりコメントを残したりするような仕組みはなく、一方通行のコミュニケーションしか行えない状況でした。
                <br />
                そこで、デジタル世界でのファンコミュニティを形成し、双方向にコミュニケーションが行える新システムを構築することになりました。
                <br />
                「釣具を売る」という事を超えて「釣りの楽しさ」を届ける、すなわち、「釣具のポイント」から「釣りのポイント」への転換を図るプロジェクトとしてスタートしました。
              </WorkParagraph>
              <h2>取り組み内容</h2>
              <WorkBlockImagePC
                fluid={WorkDetailFluidImages('dxsolutionDescktop01')}
              />
              <WorkBlockImageSP
                fluid={WorkDetailFluidImages('dxsolutionMobile01')}
              />
              <WorkParagraph>
                &emsp;釣りに特化したSNSモバイルアプリケーションと、読み物や釣具のレビュー機能が実装されたWEBサイト、相互に連携する二つのアプリケーションシステムを構築しました。
                <br />
                どちらもエンドユーザーが能動的に情報をアップロードできる点が今まで異なり、双方向のコミュニケーションを可能とするシステムです。
                <br />
                ReactとRuby&nbsp;on&nbsp;Railsを用いてシステム実装を行い、インフラはAmazon&nbsp;WEB&nbsp;Servicesで構築。月間数百万PVのWEBサイトであった為、ロードバランシングや冗長化を行い、高アクセスに耐えうるシステム設計としました。
                <br />
                また、旧アプリとWEBサイトから新システムへ数十万件の過去データを移行する作業も実施し、これまで作り上げてきたコンテンツを捨てることなく新システムを構築しました。
                <br />
                リリース後は、日々システムの安定運用と新機能の追加開発を行いながら、継続的にサービスを育てていくことで、長期的に同社の「釣りのポイント」への転換をサポートしています。今後はSNSアプリ/WEBサイト内での行動で貯まるポイントと、実際の釣具の購買に使用できるポイントと紐付け、リアルとデジタルを繋ぐ体験を提供予定です。
              </WorkParagraph>
            </WorkDescriptionWrapper>
          </WorkContentWrapper>
        </BodyWrapper>
        <WorksOuter>
          <Works />
        </WorksOuter>
      </Root>
    </PageRoot>
  );
};
export default DxSolution;
